@import "../../../mixins";

.review-card {
  padding: rem(50) rem(60);
  width: 100%;
  max-width: rem(958);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: rem(50);

  background-color: var(--bg-white);
  border: solid 1px var(--stroke-light);
  border-radius: var(--radius-common);
  overflow: hidden;

  transition: box-shadow var(--animation-default);

  @include mediaBigDesktop {
    padding: big(50) big(60);
    max-width: big(958);
    gap: big(50);
  }

  @include mediaDesktop {
    padding: rem(40) rem(50);
    max-width: rem(745);
    gap: rem(40);
  }

  @include mediaLaptop {
    padding: rem(30);
    max-width: rem(555);
    gap: rem(30);

    border-radius: var(--radius-secondary);
  }

  @include mediaTablet {
    padding: rem(20);
    max-width: rem(334);
    flex-direction: column;
    gap: rem(20);
  }

  @include mediaMobile {
    max-width: rem(270);
    gap: rem(15);
  }

  @include hover {
    box-shadow: var(--shadow-small);
  }

  &__avatar {
    position: relative;

    width: rem(250);
    height: rem(250);
    display: block;
    flex-shrink: 0;

    @include mediaBigDesktop {
      width: big(250);
      height: big(250);
    }

    @include mediaDesktop {
      width: rem(228);
      height: rem(228);
    }

    @include mediaLaptop {
      width: rem(170);
      height: rem(170);
    }

    @include mediaTablet {
      width: rem(150);
      height: rem(150);
    }

    @include mediaMobile {
      width: rem(120);
      height: rem(120);
    }

    @include hover {
      & .review-card__play {
        background-color: var(--accent-primary-hover);

        & .icon {
          transform: scale(1.2);
        }
      }
    }

    & .image {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;

      width: 100%;
      height: 100%;

      border-radius: var(--radius-infinity);
      overflow: hidden;

      pointer-events: none;
      user-select: none;

      & img,
      & picture {
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__play {
    position: absolute;
    bottom: rem(-5);
    left: rem(15);
    z-index: 2;
    margin: 0;

    padding: 0;
    width: rem(64);
    height: rem(64);
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--accent-primary-default);
    border: solid 5px var(--bg-white);
    border-radius: var(--radius-infinity);

    transition: background-color var(--animation-default);

    pointer-events: none;
    user-select: none;

    @include mediaBigDesktop {
      bottom: big(-5);
      left: big(15);

      width: big(64);
      height: big(64);

      border-width: big(5);
    }

    @include mediaDesktop {
      bottom: rem(-2);

      width: rem(50);
      height: rem(50);
    }

    @include mediaLaptop {
      bottom: 0;
      left: 0;
    }

    @include mediaTablet {
      bottom: rem(-4);
      left: rem(1);

      width: rem(42);
      height: rem(42);

      border-width: rem(4);
    }

    & .icon {
      width: rem(19);
      height: rem(19);

      fill: var(--bg-white);

      transition: transform var(--animation-default);

      @include mediaBigDesktop {
        width: big(19);
        height: big(19);
      }

      @include mediaDesktop {
        width: rem(17);
        height: rem(17);
      }

      @include mediaTablet {
        width: rem(15);
        height: rem(15);
      }
    }
  }

  &__wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }

    @include mediaLaptop {
      gap: rem(15);
    }

    @include mediaMobile {
      gap: rem(10);
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: rem(20);

    @include mediaBigDesktop {
      gap: big(20);
    }
  }

  &__logo {
    width: rem(64);
    height: rem(64);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    background-color: var(--bg-extralight);
    border-radius: var(--radius-tertiary);

    @include mediaBigDesktop {
      width: big(64);
      height: big(64);
    }

    @include mediaDesktop {
      width: rem(54);
      height: rem(54);
    }

    @include mediaTablet {
      width: rem(44);
      height: rem(44);
    }

    & img {
      width: 74%;
      height: auto;
    }
  }

  &__info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(5);

    @include mediaBigDesktop {
      gap: big(5);
    }

    @include mediaMobile {
      gap: rem(3);
    }
  }

  &__name {
    width: 100%;
    display: block;

    color: var(--text-dark-primary);
    font: var(--font-body-L-m);

    @include mediaDesktop {
      font-size: rem(18);
    }

    @include mediaLaptop {
      font: var(--font-body-L-sb);
    }
  }

  &__position {
    width: 100%;
    display: block;

    color: var(--text-dark-tertiary);
    font: var(--font-body-S-r);

    & a {
      color: var(--accent-primary-default);
      text-decoration: none;

      transition: color var(--animation-default);

      @include hover {
        color: var(--text-dark-primary);
      }
    }
  }

  &__text {
    width: 100%;
    display: -webkit-box;
    align-self: stretch;

    color: var(--text-dark-primary);
    font: var(--font-body-M-r);
    text-overflow: ellipsis;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;

    overflow: hidden;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: rem(15);

    @include mediaBigDesktop {
      gap: big(15);
    }

    @include mediaTablet {
      gap: rem(10);
    }
  }
}
